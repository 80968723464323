import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import update from 'immutability-helper';

import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';
import {useRequest} from '../../../../common/hooks';
import {
  getConnectionConfigReq as getAmocrmConnectionConfigReq
  // updateConnectionConfigReq as updateAmocrmConnectionConfigReq
} from '../../../../common/api/amo.js';
import {getConnectionConfigReq as getAvitoConnectionConfigReq} from 'common/api/avito.js';
import {getConnectionConfigReq as getBitrix24ConnectionConfigReq} from '../../../../common/api/bitrix24.js';
import {
  fetchGroups,
  getConnectionConfigReq as getMondayConnectionConfigReq
} from '../../../../common/api/monday.js';
import {
  fetchConnection,
  fetchStages
} from '../../../../common/api/pipedrive.js';
// import {trimValues} from '../../../../common/actions.js';
import {useAppContext} from 'app-context.js';

import Config from './config/config.jsx';
import Popup from './popup/popup.jsx';
import Alert from './alert/alert.jsx';
import SettingsContext from './settings-context.js';

const Settings = () => {
  const {user} = useAppContext();

  const {
    client,
    settingsId /* connectionRequiringPreset, setConnectionRequiringPreset */
  } = useConnectionListContext();

  const {connection, justCrm} = useConnectionContext();

  const [connectionConfig, setConnectionConfig] = useState();

  const [groupList, setGroupList] = useState();
  const [boards, setBoards] = useState({});

  const [isClosable, setIsClosable] = useState(true);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const {crmRequest} = useRequest();

  const updateConnectionConfig = async res => {
    let keys = [];

    switch (justCrm) {
      case 'bitrix24':
        keys = [
          'first_message',
          'group',
          'line_id',
          'lines',
          'show_chat_in_timeline'
        ];
        break;
      case 'amocrm':
        keys = [
          'closed',
          'first_message',
          'group',
          'if_contact',
          'manager_sign',
          'pipeline_settings',
          'strategy'
        ];
        break;
      case 'monday':
        keys = [
          'board_id',
          'disable_item',
          'disable_update',
          'group_id',
          'is_group',
          'responsible_id',
          'strategy'
        ];

        break;
      case 'pipedrive':
        keys = [
          'create_new_if_close',
          'is_group',
          'phone',
          'pipeline_id',
          'responsible_ids',
          'stage_id',
          'strategy',
          'write_first',
          'messaging_notify'
        ];
        break;
    }

    if (client.crm == 'AVITO') {
      keys = ['pipeline_settings', 'strategy'];
    }

    setConnectionConfig(_.pick(res, keys));

    if (justCrm == 'monday') {
      if (!('board_id' in res) || !res.board_id) setGroupList([]);
    }

    if (Object.keys(res).length) {
      setIsClosable(false);
    }
  };

  const getConnectionConfig = async () => {
    let params = {};

    switch (justCrm) {
      case 'bitrix24':
        params = getBitrix24ConnectionConfigReq({
          domain: client.domain,
          chat_id: connection.id
        });
        break;
      case 'amocrm':
        params = getAmocrmConnectionConfigReq({
          domain: client.domain,
          phone: connection.phone
        });
        break;
      case 'monday':
        params = getMondayConnectionConfigReq({
          domain: client.domain,
          phone: connection.phone
        });

        break;
      case 'pipedrive':
        params = fetchConnection(client.domain, connection.chat_key);
        break;
    }

    if (client.crm == 'AVITO') {
      params = getAvitoConnectionConfigReq({
        domain: client.domain,
        id: connection.id
      });
    }

    const res = await crmRequest({
      ...params,
      crm: client.crm,
      noNotice: client.isCustomer || justCrm == 'pipedrive'
    });

    updateConnectionConfig(res);
  };

  useEffect(() => {
    if (
      user.crm != 'LK' &&
      connection.phone &&
      connectionConfig &&
      !Object.keys(connectionConfig).length
    ) {
      getConnectionConfig();
    }
  }, [connection.phone]);

  const requestGroupList = async boardId => {
    if (!boardId) return [];
    else if (boardId in boards) return boards[boardId];

    const res = await crmRequest({
      ...fetchGroups(client.domain, boardId),
      crm: client.crm,
      noNotice: client.isCustomer
    });

    if (typeof res?.result != 'object' || !Object.keys(res?.result).length)
      return [];

    const groups = res.result.filter(item => !item.deleted);

    setBoards(prevValue => update(prevValue, {[boardId]: {$set: groups}}));
    return groups;
  };

  const getGroupList = async () => {
    setGroupList(undefined);
    const res = await requestGroupList(connectionConfig.board_id);
    setGroupList(res);
  };

  useEffect(() => {
    if (connectionConfig?.board_id) getGroupList();
  }, [connectionConfig?.board_id]);

  const getStages = async () => {
    const res = await crmRequest({
      ...fetchStages(client.domain),
      domain: client.isCustomer ? client.domain : undefined,
      crm: 'PIPEDRIVE'
    });

    setGroupList(Array.isArray(res) ? res : []);
  };

  useEffect(() => {
    if (justCrm == 'pipedrive') getStages();
  }, []);

  useEffect(() => {
    if (justCrm == 'monday' && !connection.phone) {
      setConnectionConfig({});
      return;
    } else if (client.crm == 'LK') return setConnectionConfig({});

    getConnectionConfig();
  }, []);

  // const presetConnectionConfig = async values => {
  //   const trimmedValues = trimValues(values);

  //   const res = await crmRequest({
  //     crm: client.crm,
  //     ...updateAmocrmConnectionConfigReq({
  //       domain: client.domain,
  //       phone: connection.phone,
  //       data: trimmedValues
  //     })
  //   });

  //   if (Object.keys(res).length > 3) {
  //     updateConnectionConfig(res);
  //   }
  // };

  // useEffect(() => {
  //   if (
  //     connectionRequiringPreset === connection.id &&
  //     justCrm === 'amocrm' &&
  //     connectionConfig &&
  //     pipelineList
  //   ) {
  //     presetConnectionConfig({
  //       ...connectionConfig,
  //       closed: false,
  //       pipeline_settings: pipelineList[0]?.id
  //         ? [
  //             {
  //               pipe_id: pipelineList[0].id,
  //               status_id: pipelineList[0]?.statuses?.[0]?.id | null,
  //               manager_id: null
  //             }
  //           ]
  //         : []
  //     });

  //     setConnectionRequiringPreset(-1);
  //   }
  // }, [connection.phone]);

  return (
    <SettingsContext.Provider
      value={{
        connectionConfig,
        groupList,
        setIsAlertOpen,
        updateConnectionConfig,
        requestGroupList
      }}
    >
      {client.crm != 'LK' && <Config />}
      {settingsId == connection.id && <Popup isClosable={isClosable} />}
      {isAlertOpen && <Alert />}
    </SettingsContext.Provider>
  );
};

export default Settings;
