import React, {useState, useEffect} from 'react';
import {t} from 'ttag';

import Popup from '../../../../components/popup/popup.jsx';
import * as Title from '../../../../components/title/title.jsx';
import Nav, {NavBar, NavTab} from '../../../../components/nav/nav.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useMenuContext} from '../menu-context.js';

import Bitrix24 from './bitrix24/bitrix24.jsx';
import Monday from './monday/monday';
import Notifications from './notifications/notifications.jsx';
import Partnership from './partnership/partnership.jsx';
import Pipedrive from './pipedrive/pipedrive';
import Profile from './profile/profile.jsx';
import TelphinCustomer from './telphin-customer/telphin-customer.jsx';
import Theme from './theme/theme.jsx';
import WhatcrmWeb from './whatcrm-web/whatcrm-web.jsx';

const Settings = () => {
  const {user, lang, isTelphinClientFormOpen, setIsTelphinClientFormOpen} =
    useAppContext();

  const {setIsSettingsOpen} = useMenuContext();
  const [tab, setTab] = useState('profile');

  useEffect(() => {
    if (isTelphinClientFormOpen) {
      setTab('telphinCustomer');
      setIsTelphinClientFormOpen(false);
    }
  }, []);

  const options = [
    {
      value: 'profile',
      label: t`Profile`
    }
  ];

  if (lang === 'ru') {
    options.push({
      value: 'telphinCustomer',
      label: 'Клиент «Телфин»'
    });
  }

  if (user.is_admin > 0) {
    options.push({
      value: 'partnership',
      label: t`Partnership`
    });
  }

  options.push({
    value: 'whatcrm-web',
    label: 'Whatcrm Web'
  });

  if (
    process.env.REACT_APP_ENV === 'dev' ||
    user.domain == 'wappim.amocrm.ru' ||
    user.domain == 'whatcrm-demo.monday.com'
  ) {
    options.push({
      value: 'notifications',
      label: t`Notifications`
    });
  }

  options.push({
    value: 'theme',
    label: t`Theme`
  });

  const isBitrix24 = user.crm === 'B24' || user.crm === 'TELPHGRAM';
  const isMonday = user.crm == 'MONDAY' || user.crm == 'TGMONDAY';
  const isPipedrive = user.crm == 'PIPEDRIVE';

  if (isBitrix24) options.push({label: t`Bitrix24`, value: 'bitrix24'});
  else if (isMonday) options.push({label: 'monday', value: 'monday'});
  else if (isPipedrive) options.push({label: 'Pipedrive', value: 'pipedrive'});

  return (
    <Popup width={600} onClick={() => setIsSettingsOpen(false)}>
      <Title.H2 style={{marginBottom: 12}}>{t`Settings`}</Title.H2>

      <Nav options={options} value={tab} onChange={setTab}>
        <NavBar style={{marginBottom: 16}} />

        <NavTab tab="profile">
          <Profile />
        </NavTab>

        {lang === 'ru' && (
          <NavTab tab="telphinCustomer">
            <TelphinCustomer />
          </NavTab>
        )}

        <NavTab tab="partnership">
          <Partnership />
        </NavTab>

        <NavTab tab="whatcrm-web">
          <WhatcrmWeb />
        </NavTab>

        <NavTab tab="notifications">
          <Notifications />
        </NavTab>

        <NavTab tab="theme">
          <Theme />
        </NavTab>

        {isBitrix24 ? (
          <NavTab tab="bitrix24">
            <Bitrix24 />
          </NavTab>
        ) : isMonday ? (
          <NavTab tab="monday">
            <Monday />
          </NavTab>
        ) : (
          isPipedrive && (
            <NavTab tab="pipedrive">
              <Pipedrive />
            </NavTab>
          )
        )}
      </Nav>
    </Popup>
  );
};

export default Settings;
